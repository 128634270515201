import React, { FC } from "react";

type Props = {
  children: any;
};

const InnerWrapper: FC<Props> = ({ children }) => {
  return <div style={{ maxWidth: "2200px", margin: "0 auto" }}>{children}</div>;
};

export default InnerWrapper;
