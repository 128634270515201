import React, { FC } from "react";
import arrowRightWhite from "../../../images/arrowRightWhite.svg";
import { motion } from "framer-motion";
import { Link } from "gatsby";

type Props = {
  classNamesForButton: string;
  title?: string;
  typeOfButton?: boolean;
  linkRoute?: string;
};

const ContactUsButton: FC<Props> = ({
  classNamesForButton,
  title = "Contact us",
  typeOfButton = false,
  linkRoute = "#",
}) => {
  return (
    <Link
      to={title === "Contact us" ? "/contact" : linkRoute}
      style={{ alignSelf: "flex-start" }}
    >
      <motion.button
        type={typeOfButton ? "submit" : "button"}
        initial={{ opacity: 0, visibility: "hidden" }}
        animate={{ opacity: 1, visibility: "visible" }}
        className={classNamesForButton}
      >
        <span>{title}</span>

        <motion.img
          height="auto"
          width="auto"
          src={arrowRightWhite}
          alt="contact us"
        />
      </motion.button>
    </Link>
  );
};

export default ContactUsButton;
