import React from "react";
import * as styles from "./NotFoundMain.module.scss";
import notFoundNumber from "../../images/notFoundNumber.svg";
import ContactUsButton from "../Shared/ContactUsButton/ContactUsButton";
import InnerWrapper from "../Shared/InnerWrapper/InnerWrapper";
import CircleOutline from "../Shared/CircleOutlineAndBlur/CircleOutline";
import CircleBlur from "../Shared/CircleOutlineAndBlur/CircleBlur";

export const NotFoundMain = () => {
  return (
    <InnerWrapper>
      <div className={styles.notFoundWrapper}>
        <div style={{ zIndex: 20 }} className={styles.notFoundBody}>
          <div className={styles.notFoundTitle}>
            <h1>PAGE</h1>
            <h1>NOT_FOUND</h1>
          </div>
          <div className={styles.notFoundDesc}>
            <span>
              The page you requested could not be found. Try refining your
              search, or use the navigation above to locate the post.
            </span>
            <ContactUsButton
              linkRoute={"/"}
              title={"Home page"}
              classNamesForButton={styles.returnToHomeButton}
            />
          </div>
        </div>
        <img src={notFoundNumber} className={styles.notFoundImage} alt="" />
        <div className={styles.backgroundCircles}>
          <CircleOutline
            isBlurVisible={true}
            initialScale={0.3}
            animateScale={1}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundCircleLeft}
          ></CircleOutline>
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.5}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeft}
          />
          <CircleBlur
            initialScale={0.1}
            animateScale={1}
            initialOpacity={0}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroWhiteBlurCirce}
          />
          <CircleBlur
            initialScale={0.1}
            initialOpacity={0}
            animateScale={1.5}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroBackgroundInnerCircleLeftSecond}
          />
          <CircleBlur
            initialScale={0.1}
            animateScale={1}
            initialOpacity={0}
            animateOpacity={0.15}
            transitionType={"easeIn"}
            transitionDelay={0.2}
            transitionDuration={1}
            circleStyleClass={styles.heroWhiteBlurCirceSecond}
          />
        </div>
      </div>
    </InnerWrapper>
  );
};
