import React, { FC } from "react";
import Layout from "../components/Layout/Layout";
import { NotFoundMain } from "../components/NotFoundUiComponents/NotFoundMain";
import { SEO } from "../components/seo";

const NotFoundPage: FC = () => (
  <Layout location="/">
    <>
      <SEO title="404" description="404 - Page not found" type="website" />
      <div style={{ color: "white" }}>
        <NotFoundMain />
      </div>
    </>
  </Layout>
);

export default NotFoundPage;
