// extracted by mini-css-extract-plugin
export var backgroundCircles = "NotFoundMain-module--backgroundCircles--kQYra";
export var heroBackgroundCircleLeft = "NotFoundMain-module--heroBackgroundCircleLeft--yTGfR";
export var heroBackgroundInnerCircleLeft = "NotFoundMain-module--heroBackgroundInnerCircleLeft--R+usm";
export var heroBackgroundInnerCircleLeftSecond = "NotFoundMain-module--heroBackgroundInnerCircleLeftSecond--w2Eg9";
export var heroWhiteBlurCirce = "NotFoundMain-module--heroWhiteBlurCirce--fd3G4";
export var heroWhiteBlurCirceSecond = "NotFoundMain-module--heroWhiteBlurCirceSecond--tZJK1";
export var notFoundBody = "NotFoundMain-module--notFoundBody--bjY2l";
export var notFoundDesc = "NotFoundMain-module--notFoundDesc--mLJvE";
export var notFoundImage = "NotFoundMain-module--notFoundImage--Ng0RR";
export var notFoundTitle = "NotFoundMain-module--notFoundTitle--QfC9-";
export var notFoundWrapper = "NotFoundMain-module--notFoundWrapper--l3N5c";
export var returnToHomeButton = "NotFoundMain-module--returnToHomeButton--obH78";